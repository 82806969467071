import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TextLargeAndSmallBlockContainer = styled.section`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-auto-rows: min-content;
	grid-gap: 12px;
	border-top: 2px solid var(--primary-colour);

	@media (min-width: 768px) {
		border-top: 0;
		grid-gap: 24px;
		& > * {
			/* grid-row: 1; */
		}
	}

	@media (min-width: 1200px) {
	}
`

const Heading = styled.h1`
	grid-column: 1 / span 12;
	font-size: 18px;
	font-weight: 500;

	padding-top: 12px;

	@media (min-width: 768px) {
		border-top: 2px solid var(--primary-colour);
		grid-column: 1 / span 2;
		font-size: 24px;
	}
`

const Paragraph = styled.p`
	font-size: 14px;
	line-height: 1.4;

	grid-column: 1 / span 12;
	/* grid-row: 2; */
	align-self: center;

	@media (min-width: 768px) {
		grid-column: 4 / span 6;
		margin-top: 12px;
		font-size: 16px;
		/* grid-row: 1; */
	}
`

const LinkOutIcon = styled.svg`
	transform: translateY(3px);
	width: 16px;
	height: 16px;
	margin-left: 10px;

	& > g > polyline,
	& > g > line {
		fill: none;
		stroke: var(--primary-colour);
		stroke-miterlimit: 10;
	}
`

const TextLargeAndSmallBlock = props => (
	<TextLargeAndSmallBlockContainer>
		{props.heading !== '' ? <Heading>{props.heading}</Heading> : null}
		{props.paragraphs !== null
			? props.paragraphs.map((paragraph, index) => (
				<Paragraph key={index}>{paragraph}</Paragraph>
			  ))
			: null}
		{props.link !== null ? (
			<Paragraph>
				<a href={props.link.url} name="link-box">
					{props.link.label}
					<LinkOutIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
						<g>
							<polyline points="15.5,8 15.5,15.5 0.5,15.5 0.5,0.5 8,0.5 	" />
						</g>
						<g>
							<polyline points="10.5,0.5 15.5,0.5 15.5,5.5 	" />
							<line x1="8.5" y1="7.5" x2="15.5" y2="0.5" />
						</g>
					</LinkOutIcon>
				</a>
			</Paragraph>
		) : null}
	</TextLargeAndSmallBlockContainer>
)

TextLargeAndSmallBlock.propTypes = {
	heading: PropTypes.string,
	paragraphs: PropTypes.array,
	link: PropTypes.object
}

TextLargeAndSmallBlock.defaultProps = {
	heading: 'largeTextLeft',
	paragraphs: null,
	link: null
}

export default TextLargeAndSmallBlock
