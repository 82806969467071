import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
// import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SVGSeparator from '../components/project-blocks/separator'
// import InformationSectionBlock from '../components/project-blocks/information-section'
// import TextSingleBlock from '../components/project-blocks/text-single'
import TextLargeAndSmallBlock from '../components/project-blocks/text-large-and-small'
// import ImageSingleBlock from '../components/project-blocks/image-single'
// import ImageDoubleBlock from '../components/project-blocks/image-double'
// import TextAndImageBlock from '../components/project-blocks/text-and-image'
//
// import { informationSectionData } from '../data/case-study-data--castle'

const HeaderImage = styled.div`
	height: auto;

	margin-top: 24px;
	margin-bottom: 12px;

	& > * {
		width: auto;
		max-height: 25vh;
		/* height: fill-available; */
	}
`

const HeadingGroup = styled.header`
	display: grid;
	grid-gap: 6px;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 1px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Subtitle = styled.h2`
	font-size: 18px;
	opacity: 0.75;

	@media (min-width: 768px) {
		font-size: 21px;
	}
`

const Castle = ({ data }) => (
	<Layout>
		<SEO title="Castle UX / UI Case Study" />
		<HeaderImage>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 512 512"
				style={{ fill: 'white' }}
			>
				<path
					display="none"
					d="M272.17,221.09L419.51,448h-93.43L219.29,284.75l-47.23,56.98V448H92.49V64h79.57v158.63L302.46,64h101.65
			L272.17,221.09z"
				/>
				<polygon points="265.92,235.94 419,426.82 340.87,426.82 215,270 " />
				<polygon
					display="none"
					points="172.06,341.73 172.06,222.63 302.46,64 404.11,64 "
				/>
				<g>
					<path
						d="M23.71,362.23c31.45-12.79,61.11-28.95,88.95-47.45c27.84-18.5,53.88-39.33,78.35-61.7c24.47-22.38,47.39-46.32,69.06-71.3
				c21.67-24.99,42.08-51.02,61.55-77.78l52.77,46c-24.98,24.2-50.86,47.54-77.84,69.54c-26.97,21.99-55.03,42.66-84.28,61.35
				c-29.24,18.68-59.69,35.39-91.23,49.26C89.51,344.02,56.88,355.01,23.71,362.23z"
					/>
				</g>
				<path d="M128.06,292.26V189.72V85.18h30.97H194v71.17v79.17c0,0-9,9.47-31.41,29.37C144.16,281.25,128.06,292.26,128.06,292.26z" />
				<polygon points="128.06,426.82 128.06,312.94 194,278.24 194,426.82 " />
				<g>
					<polygon points="381.64,85.18 383.27,130.85 384.9,176.52 338.02,137.17 291.13,97.83 336.39,91.51 	" />
				</g>
			</svg>
		</HeaderImage>
		<HeadingGroup>
			<Title>Konrad</Title>
			<Subtitle>Chat & Portfolio Review</Subtitle>
		</HeadingGroup>
		<SVGSeparator />
		<TextLargeAndSmallBlock
			heading={'Portfolio'}
			paragraphs={[
				'This is the portfolio presentation for your future reference if you\'d like 👍'
			]}
			link={data.portfolio}
		/>
		<SVGSeparator />
		<TextLargeAndSmallBlock heading={'Resumé'} link={data.resume} />
		<SVGSeparator />
		<TextLargeAndSmallBlock heading={'References'} link={data.references} />
		<SVGSeparator />
		<Link to="/">← Back</Link>
	</Layout>
)

Castle.propTypes = {
	data: PropTypes.any.isRequired
}

export const pageQuery = graphql`
	query {
		portfolio: file(name: { regex: "/Portfolio/" }) {
			name
			label: name
			publicURL
			url: publicURL
		}
		resume: file(name: { regex: "/Resum/" }) {
			name
			label: name
			publicURL
			url: publicURL
		}
		references: file(name: { regex: "/References/" }) {
			name
			label: name
			publicURL
			url: publicURL
		}
	}
`

export default Castle
